/* global Raven */

const defaultLevel = "error";
const levels = ["info", "warning", "error"];

export default (e, msgLevel) => {
  const level = levels.includes(msgLevel) ? msgLevel : defaultLevel;
  return typeof Raven === "object" && Raven.isSetup()
    ? Raven.captureException(e, { tags: { widget: "omnibar-ng" }, level })
    : console[level.replace("ing", "")](
        `[cookiebar] ${e}`
      ); /* warning -> console.warn */ // eslint-disable-line no-console
};
