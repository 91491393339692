const isLanguageSupported = (code, config) => {
  const supportedLanguages = Object.keys(config.languages);
  return supportedLanguages.includes(code);
};

const browserLanguage = (config) => {
  const documentLang = document.documentElement.lang;
  const browserLang =
    window.navigator.language || window.navigator.userLanguage;

  if (isLanguageSupported(documentLang, config)) {
    return documentLang;
  }

  if (isLanguageSupported(browserLang, config)) {
    return browserLang;
  }

  return config.fallbackLanguage;
};

export { browserLanguage, isLanguageSupported };
