const baseClass = "cznic-cookiebar";

export default {
  languages: {
    cs: {
      jump: "Přeskočit na obsah",
      text: "Webové stránky sdružení CZ.NIC používají pro některé své funkce soubory cookies uložené ve vašem prohlížeči. Chcete povolit ukládání a zpracování i jiných než nezbytných informací (např. pro měření návštěvnosti, přizpůsobení stránky, marketingové informace)?",
      buttonYes: "Ano",
      buttonNo: "Ne",
      linkMore: "Více informací",
      newTab: "Odkaz se otevře v novém okně nebo záložce prohlížeče",
    },
    en: {
      jump: "Jump to content",
      text: "The CZ.NIC website uses cookies stored in your browser for some of its functions. Do you want to allow the storage and processing of non-essential information (e.g. for traffic measurement, site customization, marketing information)?",
      buttonYes: "Yes",
      buttonNo: "No",
      linkMore: "More info",
      newTab: "Link will open in a new browser window or tab",
    },
  },
  moreInfoUrl: "https://nic.cz/cookies",
  fallbackLanguage: "en",
  baseClass,
  changeYoutubeUrls: true,
  disableMatomoCookies: true,
  disableGtagCookies: true,
  defaultConsent: undefined,
  consentCookieExpire: 365 * 5,
  removeCookies: true,
  preservedCookieNames: [
    `${baseClass}-consent`,
    "csrftoken",
    "sessionid",
    "mojeidsession",
    "auth_method",
    "identifier"
  ],
};
